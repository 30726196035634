import jsPDF from "jspdf";
import "jspdf-autotable";

export const base_url = "https://api.smartdocs365.com/api/";

// export const base_url = "https://api.smartdocs365.com/api/";

const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const ID = user !== null ? user?.userid : "";

export const isValidEmail = /\S+@\S+\.\S+/;
// export const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const exportPDF = ({
  table_headers,
  table_data,
  tableName,
  fileName,
}) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = { tableName };
  //   const title = "SD 365";
  //   const headers = [
  //     ["S.No.", "Name", "Email", "Mobile", "Platform", "Policies", "Document"],
  //   ];
  const headers = [table_headers];
  const data = table_data;
  //   const data = users_list.map((elt, i) => [
  //     i + initialValue,
  //     elt.Name,
  //     elt.Email,
  //     elt.mobile,
  //     elt.platform,
  //     elt.totalpolicies,
  //     elt.totaldocument,
  //   ]);

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(tableName, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${fileName}.pdf`);
};

// value1={plan_details?.planName?.trim()}
//           value2={plan_details?.totalPdfLimit}
