import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Dashboard from "./screens/dashboard";
import UserDetail from "./screens/users/UserDetail";
import UsersList from "./screens/users/UserList";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./screens/auth/Login";
import Messages from "./screens/messages";
import NotificationList from "./screens/notification";
import AddNotification from "./screens/notification/AddNotification";
import AllUserList from "./screens/alluserpolicy";
import { Heading, Text } from "@chakra-ui/react";
import Blogs from "./screens/Blogs/Blogs";
import { AddAdmin } from "./screens/AddAdmin/AddAdmin";
import { useEffect } from "react";
import Profile from "./screens/Profile";

function App() {
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const location = useLocation();

  useEffect(() => {
    if (user == null) {
      // <Navigate to="/homepage" replace />;
      navigate("/login");
      return;
    }
    // <Navigate to="/" replace />;
  }, []);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route exact path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="/users" element={<Outlet />}>
            <Route index element={<UsersList />} />
            <Route path="user-detail" element={<Outlet />}>
              <Route index element={<UserDetail />} />
            </Route>
          </Route>
          <Route path="/messages" element={<Messages />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/notifications" element={<Outlet />}>
            <Route index element={<NotificationList />} />
            <Route path="add-notification" element={<AddNotification />} />
          </Route>
          <Route path="/blogs-list" element={<Outlet />}>
            <Route index element={<Blogs />} />
          </Route>
          {user?.consumer?.role == "super-admin" && (
            <Route path="/add-admin" element={<Outlet />}>
              <Route index element={<AddAdmin />} />
            </Route>
          )}
          <Route path="/subscription-plans" element={<Outlet />}>
            <Route index element={<AllUserList />} />
          </Route>
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Heading fontSize={"6xl"} color={"#ffbf00"}>
          404
        </Heading>
        <Heading fontSize={"2xl"} paddingBlock={2}>
          Page Not Found
        </Heading>
        <Text>Oops! You seem to be lost.</Text>
        <Text>
          Go to
          <Link
            to="/"
            style={{
              textDecorationLine: "underline",
              padding: 5,
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </Text>
      </div>
    </div>
  );
}
export default App;
