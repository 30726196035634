import React, { useState, useEffect } from 'react';
import { 
  Box, 
  VStack, 
  Heading, 
  Text, 
  Avatar, 
  Button, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalFooter, 
  ModalBody, 
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast,
  HStack
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { base_url } from '../utils/utils';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    confirmPassword: '',
    email: '',
    otp: ''
  });
  const [otpSent, setOtpSent] = useState(false);
  const [showPasswords, setShowPasswords] = useState({
    new: false,
    confirm: false
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchProfile = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('userId'));
      const userId = user?.consumer?.user_id;
      
      const res = await fetch(base_url + `login/get-admin-profile/${userId}`);
      const resJson = await res.json();
      
      if (resJson.success) {
        setProfile(resJson.profile);
        setPasswordData(prev => ({
          ...prev,
          email: resJson.profile.email_address
        }));
      }
    } catch (error) {
      toast({
        title: "Error fetching profile",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const sendOTP = async () => {
    try {
      const res = await fetch(base_url + 'login/send-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: passwordData.email
        })
      });

      const resJson = await res.json();

      if (resJson.success) {
        setOtpSent(true);
        localStorage.setItem('otpcode',resJson.otpcode);
        toast({
          title: "OTP Sent",
          description: "OTP has been sent to your email",
          status: "success",
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          title: "OTP Send Failed",
          description: resJson.message || "Unable to send OTP",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  // const handlePasswordChange = async () => {
  //   if (passwordData.newPassword !== passwordData.confirmPassword) {
  //     toast({
  //       title: "Password Mismatch",
  //       description: "New password and confirm password do not match",
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true
  //     });
  //     return;
  //   }

  //   try {
  //     const user = JSON.parse(localStorage.getItem('userId'));
  //     const userId = user?.consumer?.user_id;

  //     const res = await fetch(base_url + 'login/change-password', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         user_id: userId,
  //         email: passwordData.email,
  //         otp: passwordData.otp,
  //         new_password: passwordData.newPassword
  //       })
  //     });

  //     const resJson = await res.json();

  //     if (resJson.success) {
  //       toast({
  //         title: "Password Changed",
  //         description: "Your password has been successfully updated",
  //         status: "success",
  //         duration: 3000,
  //         isClosable: true
  //       });
  //       onClose();
  //       setPasswordData({
  //         newPassword: '',
  //         confirmPassword: '',
  //         email: profile.email_address,
  //         otp: ''
  //       });
  //       setOtpSent(false);
  //     } else {
  //       toast({
  //         title: "Password Change Failed",
  //         description: resJson.message || "Unable to change password",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true
  //       });
  //     }
  //   } catch (error) {
  //     toast({
  //       title: "Error",
  //       description: error.message,
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true
  //     });
  //   }
  // };

  const handlePasswordChange = async () => {
    // Check if passwords match
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast({
        title: "Password Mismatch",
        description: "New password and confirm password do not match",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      return;
    }
  
    // Retrieve stored OTP from localStorage
    const storedOTP = localStorage.getItem('otpcode');
  
    // Validate entered OTP against stored OTP
    if (passwordData.otp !== storedOTP) {
      toast({
        title: "Invalid OTP",
        description: "The OTP you entered is incorrect",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      return;
    }
  
    try {
      const user = JSON.parse(localStorage.getItem('userId'));
      const userId = user?.consumer?.user_id;
  
      const res = await fetch(base_url + 'login/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: userId,
          email: passwordData.email,
          otp: passwordData.otp,
          new_password: passwordData.newPassword
        })
      });
  
      const resJson = await res.json();
  
      if (resJson.success) {
        // Clear OTP from localStorage after successful password change
        localStorage.removeItem('otpcode');
  
        toast({
          title: "Password Changed",
          description: "Your password has been successfully updated",
          status: "success",
          duration: 3000,
          isClosable: true
        });
        onClose();
        setPasswordData({
          newPassword: '',
          confirmPassword: '',
          email: profile.email_address,
          otp: ''
        });
        setOtpSent(false);
      } else {
        toast({
          title: "Password Change Failed",
          description: resJson.message || "Unable to change password",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Box 
      maxW="md" 
      mx="auto" 
      mt={10} 
      p={6} 
      borderWidth={1} 
      borderRadius="lg" 
      boxShadow="lg"
      bg="white"
    >
      {profile && (
        <VStack spacing={6} align="stretch">
          <Avatar 
            size="2xl" 
            name={profile.full_name} 
            alignSelf="center" 
            bg="blue.500" 
            color="white"
          />
          
          <VStack spacing={3} align="stretch">
            <Heading size="md" textAlign="center">
              {profile.full_name}
            </Heading>
            
            <Text textAlign="center" color="gray.600">
              {profile.email_address}
            </Text>
            
            <Box>
              <Text fontWeight="bold">Personal Details:</Text>
              <Text>Mobile: {profile.mobile}</Text>
              <Text>User ID: {profile.user_id}</Text>
            </Box>
            
            <Button 
              colorScheme="blue" 
              onClick={onOpen}
              mt={4}
            >
              Change Password
            </Button>
          </VStack>
          
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Change Password</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                {!otpSent ? (
                  <Button 
                    colorScheme="blue" 
                    width="full" 
                    onClick={sendOTP}
                  >
                    Send OTP
                  </Button>
                ) : (
                  <>
                    <FormControl>
                      <FormLabel>Enter OTP</FormLabel>
                      <Input 
                        placeholder="Enter 6-digit OTP"
                        value={passwordData.otp}
                        onChange={(e) => setPasswordData(prev => ({
                          ...prev, 
                          otp: e.target.value
                        }))}
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>New Password</FormLabel>
                      <InputGroup>
                        <Input 
                          type={showPasswords.new ? "text" : "password"}
                          value={passwordData.newPassword}
                          onChange={(e) => setPasswordData(prev => ({
                            ...prev, 
                            newPassword: e.target.value
                          }))}
                        />
                        <InputRightElement>
                          <Button 
                            variant="ghost" 
                            onClick={() => setShowPasswords(prev => ({
                              ...prev, 
                              new: !prev.new
                            }))}
                          >
                            {showPasswords.new ? <ViewOffIcon /> : <ViewIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Confirm New Password</FormLabel>
                      <InputGroup>
                        <Input 
                          type={showPasswords.confirm ? "text" : "password"}
                          value={passwordData.confirmPassword}
                          onChange={(e) => setPasswordData(prev => ({
                            ...prev, 
                            confirmPassword: e.target.value
                          }))}
                        />
                        <InputRightElement>
                          <Button 
                            variant="ghost" 
                            onClick={() => setShowPasswords(prev => ({
                              ...prev, 
                              confirm: !prev.confirm
                            }))}
                          >
                            {showPasswords.confirm ? <ViewOffIcon /> : <ViewIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                {otpSent && (
                  <Button 
                    colorScheme="blue" 
                    mr={3} 
                    onClick={handlePasswordChange}
                  >
                    Change Password
                  </Button>
                )}
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      )}
    </Box>
  );
}

export default Profile;